.mapboxglUserLocationDot {
  background-color: #1da1f2;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.mapboxglUserLocationDot::before {
  background-color: #1da1f2;
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  -webkit-animation: mapboxgl-user-location-dot-pulse 2s infinite;
  -moz-animation: mapboxgl-user-location-dot-pulse 2s infinite;
  -ms-animation: mapboxgl-user-location-dot-pulse 2s infinite;
  animation: mapboxgl-user-location-dot-pulse 2s infinite;
}

.mapboxglUserLocationDot::after {
  border-radius: 50%;
  border: 2px solid #fff;
  content: "";
  height: 19px;
  left: -2px;
  position: absolute;
  top: -2px;
  width: 19px;
  box-sizing: border-box;
}

@-webkit-keyframes mapboxgl-user-location-dot-pulse {
  0% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
  70% {
    -webkit-transform: scale(3);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@-ms-keyframes mapboxgl-user-location-dot-pulse {
  0% {
    -ms-transform: scale(1);
    opacity: 1;
  }
  70% {
    -ms-transform: scale(3);
    opacity: 0;
  }
  100% {
    -ms-transform: scale(1);
    opacity: 0;
  }
}

@keyframes mapboxgl-user-location-dot-pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  70% {
    transform: scale(3);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.mapboxgl-user-location-dot-stale {
  background-color: #aaa;
}

.mapboxgl-user-location-dot-stale::after {
  display: none;
}
